import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Seo from '~components/Seo'
import DataViewer from '~utils/DataViewer'
import { mobile, tablet, PageHeader, Headline, Subtitle } from '~styles/global'
import ProductGrid from '~components/ProductGrid'
import styled from '@emotion/styled'

const ProductsPage = ({ data }) => {
	const { page } = data

	return(
		<>
			<Seo 
				title={page.title}
				metaTitle={page.seo?.metaTitle}
				description={page.seo?.metaDescription}
				image={page.seo?.socialImage}
			/>
			<PageHeader>
				<Headline>{page.headline}</Headline>
				<Subtitle>{page.subtitle}</Subtitle>
			</PageHeader>
			<Products products={page.productGrid}/>
			<DataViewer data={page} name="page"/>
		</>
	)
}

const Products = styled(ProductGrid)`
	margin-bottom: var(--xxxxl);
`

ProductsPage.propTypes = {
	data: PropTypes.object,
}

export const query = graphql`
  query ProductsPageQuery($slug: String) {
    page: sanityProductsPage(slug: {current: {eq: $slug}}) {
      title
			headline
			subtitle
			productGrid {
				...productThumb
			}
			seo {
				...seo
			}
    }
  }
`

export default ProductsPage